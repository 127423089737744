import { useState, useEffect } from 'react';

import { checkIfTestStagingEnv } from 'services/helpers';

// Calculates Gregorian Easter Sunday for a given year
function getEasterSunday(year) {
  // Anonymous Gregorian algorithm
  const a = year % 19;
  const b = Math.floor(year / 100);
  const c = year % 100;
  const d = Math.floor(b / 4);
  const e = b % 4;
  const f = Math.floor((b + 8) / 25);
  const g = Math.floor((b - f + 1) / 3);
  const h = (19 * a + b - d - g + 15) % 30;
  const i = Math.floor(c / 4);
  const k = c % 4;
  const l = (32 + 2 * e + 2 * i - h - k) % 7;
  const m = Math.floor((a + 11 * h + 22 * l) / 451);
  const month = Math.floor((h + l - 7 * m + 114) / 31); // Month (1-12)
  const day = ((h + l - 7 * m + 114) % 31) + 1; // Day (1-31)
  // Creates date in local timezone. Consider UTC for more consistency if needed.
  return new Date(year, month - 1, day); // month - 1 because Date month is 0-indexed
}

// Helper to normalize date (set time to 00:00:00:00) for accurate day comparison
function normalizeDate(date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 * Custom hook to determine if the current date falls within the week leading
 * up to Easter Sunday, including Easter Monday.
 * (Monday before Easter Sunday -> Easter Monday inclusive)
 * @returns {boolean} True if it's Easter week, false otherwise.
 */
export function useIsEasterWeek() {
  const [isEasterWeek, setIsEasterWeek] = useState(false);

  useEffect(() => {
    const checkDate = () => {
      try {
        const today = normalizeDate(new Date());
        const currentYear = today.getFullYear();
        const easterSunday = getEasterSunday(currentYear);

        // If environment is not prod, always show :)
        // checkIfTestStagingEnv returns null if environment is prod
        if (checkIfTestStagingEnv(window.location.href)) {
          setIsEasterWeek(true);
          return;
        }

        // Calculate start date: Monday two weeks before Easter Sunday (-13 days)
        const startMonday = normalizeDate(easterSunday);
        startMonday.setDate(easterSunday.getDate() - 13);

        // Calculate end date: Easter Monday (+1 day)
        const endMonday = normalizeDate(easterSunday);
        endMonday.setDate(easterSunday.getDate() + 1);

        // Check if today is within the range
        const shouldShow = today >= startMonday && today <= endMonday;
        setIsEasterWeek(shouldShow);

        // Optional: Log the calculated dates for debugging
        // console.log(`Easter Check (${currentYear}):`);
        // console.log(`  Today: ${today.toDateString()}`);
        // console.log(`  Easter Sunday: ${easterSunday.toDateString()}`);
        // console.log(`  Show Start (Mon): ${startMonday.toDateString()}`);
        // console.log(`  Show End (Easter Mon): ${endMonday.toDateString()}`);
        // console.log(`  Should show? ${shouldShow}`);
      } catch (error) {
        setIsEasterWeek(false); // Default to false on error
      }
    };

    checkDate();

    // Optional: Set up an interval to re-check daily if the app runs for long periods
    // const intervalId = setInterval(checkDate, 24 * 60 * 60 * 1000); // Check once a day
    // return () => clearInterval(intervalId); // Cleanup interval
  }, []); // Run only once on mount

  return isEasterWeek;
}

// Default export for convenience
export default useIsEasterWeek;
