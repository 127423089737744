import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { pending, rejected } from 'redux-saga-thunk';
import { t, Trans } from '@lingui/macro';

import { CleanReconsModal, AnimateAlert } from 'components';

import { cleanReconsApi } from 'services/apihelpers';
import { fromCompany, fromResource } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { useParams } from 'react-router-dom';

function CleanReconsModalContainer(props) {
  const { toggleModal, refetchParent, account } = props;
  const { accountingPeriod } = useParams();
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const company = useSelector(fromCompany.getCompany);
  const dispatch = useDispatch();
  const query = {
    companySid: company.currentCompanySID,
    accountId: account,
  };

  const data = useSelector((state) =>
    fromResource.getList(state, cleanReconsApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${cleanReconsApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${cleanReconsApi}ListRead`),
  );

  const fetchData = () => {
    dispatch(resourceListReadRequest(cleanReconsApi, query));
  };

  const _closeModal = (refetch = false) => {
    toggleModal();
    if (refetch === true && typeof refetchParent === 'function') {
      setTimeout(() => {
        refetchParent();
      }, 1000);
    }
  };

  const handleCleanRecons = async () => {
    try {
      await dispatch(resourceCreateRequest(cleanReconsApi, query));
      _closeModal(true);
    } catch (error) {
      toast.error('Error cleaning reconciliations:', error);
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleAction = async (action) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'doCleanRecons': {
          await handleCleanRecons();
          break;
        }

        case 'showAlert': {
          setShowAlert(true);
          break;
        }

        case 'hideAlert': {
          setShowAlert(false);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(fetchData, []);

  return (
    <>
      <AnimateAlert
        {...{
          showCancel: true,
          show: showAlert,
          type: 'warning',
          title: t`Warning`,
          onCancel: () => {
            handleAction('hideAlert');
          },
          onConfirm: () => {
            handleAction('doCleanRecons');
            handleAction('hideAlert');
          },
        }}
      >
        <Trans>
          You&apos;re about to remove {data.length} reconciliations that were
          marked as invalid by Accountflow. Are you sure you want to proceed?
        </Trans>
      </AnimateAlert>
      <CleanReconsModal
        {...{
          data,
          failed,
          loading,
          handleAction,
          actionSubmitting,
          toPeriod: accountingPeriod,
          account,
        }}
        toggleModal={_closeModal}
      />
    </>
  );
}

export default CleanReconsModalContainer;
