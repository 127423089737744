import React, { useState, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { IoMdHelpCircle } from '@react-icons/all-files/io/IoMdHelpCircle';
import { GiEasterEgg } from '@react-icons/all-files/gi/GiEasterEgg';
import { ButtonGroup } from 'react-bootstrap';

import { Button, SudokuModal } from 'components';

import BalanceDataModal from 'containers/BalanceDataModal';
import ReleaseNoteModal from 'containers/ReleaseNoteModal';
import ChangeCompanyYear from 'containers/ChangeCompanyYear';
import ChangeCompanyModal from 'containers/ChangeCompanyModal';
// import DeadlineCalendarModal from 'containers/DeadlineCalendarModal';

import getErrorMessage from 'services/helpers/getErrorMessage';
import { lastOpenReleaseDateApi } from 'services/apihelpers';
import { resourceListReadRequest } from 'store/resource/actions';
import { useIsEasterWeek } from 'hooks/useIsEasterWeek';

import client from 'services/utils/sanity';

function CollapsableMenuGroup(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const isEasterTime = useIsEasterWeek();
  const [showSudokuModal, setShowSudokuModal] = useState(false);

  const [releaseNote, setReleaseNote] = useState(null);

  const fetchData = async () => {
    client
      .fetch(
        `*[_type == "release" && language == "${
          user?.languageCode || i18n.locale
        }"] {
        date,
        body,
        title,
        publish,
        language,
        _updatedAt,
      }`,
      )
      .then((data) => {
        if (data?.length > 0) {
          setReleaseNote(data[0]);
        }
      })
      .catch((e) => {
        toast.error(getErrorMessage(e));
      });

    try {
      await dispatch(resourceListReadRequest(lastOpenReleaseDateApi));
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };
  const openHelpPage = () =>
    window.open(
      'https://help.accountflow.com/',
      '_blank',
      'noopener,noreferrer',
    );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ButtonsContainer>
      {/* <DeadlineCalendarModal /> */}
      {isEasterTime && (
        <>
          <SudokuModal
            show={showSudokuModal}
            onHide={() => setShowSudokuModal(false)}
          />
          <ButtonGroup bsSize="small">
            <EasterEggButton onClick={() => setShowSudokuModal(true)}>
              <EasterEggIcon />
            </EasterEggButton>
          </ButtonGroup>
        </>
      )}
      <ButtonGroup bsSize="small">
        <HelpButton fill onClick={openHelpPage} title={t`Help`}>
          <HelpIcon />
        </HelpButton>
      </ButtonGroup>
      {releaseNote?.publish && (
        <ReleaseNoteModal {...props} releaseNote={releaseNote} />
      )}
      <ChangeCompanyYear {...props} />
      <BalanceDataModal {...props} />
      <ChangeCompanyModal {...props} />
    </ButtonsContainer>
  );
}

export default CollapsableMenuGroup;

const HelpIcon = styled((props) => <IoMdHelpCircle {...props} />)`
  font-size: 18px;
  margin-bottom: -4px;
`;

const EasterEggIcon = styled((props) => <GiEasterEgg {...props} />)`
  font-size: 18px;
  margin-bottom: -4px;
`;

const HelpButton = styled(Button)`
  background: #b1b1b1 !important;
  border-color: #b1b1b1 !important;
  padding: 4.5px 8px !important;

  :hover {
    background: #737373 !important;
    border-color: #737373 !important;
  }
`;

const rainbowSlide = keyframes`
  from { background-position: 0% 50%; }
  to { background-position: 300% 50%; }
`;

const EasterEggButton = styled(Button)`
  background: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  padding: 4.5px 8px !important;
  border-radius: 6px !important;
  border: none !important;
  opacity: 0.6 !important;
  transition: transform 0.2s ease, opacity 0.2s ease !important;
  position: relative !important;
  overflow: hidden !important;
  z-index: 0 !important;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      90deg,
      #ff008c,
      #ffcd00,
      #00ff88,
      #00bfff,
      #ff008c
    );
    background-size: 300% 300%;
    opacity: 0;
    transition: opacity 0.3s ease !important;
    z-index: -1 !important; /* Move behind text */
  }

  &:hover {
    opacity: 1 !important;
    transform: scale(1.2) !important;

    &::before {
      opacity: 1 !important;
      animation: ${rainbowSlide} 0.8s linear infinite !important;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 18px;
  gap: 5px;

  @media (max-width: 992px) {
    justify-content: flex-start;
    padding-top: 0;
    position: absolute;
    left: 10px;
    top: 22px;
  }

  @media (max-width: 500px) {
    & > div:nth-child(1),
    & > div:nth-child(2) {
      display: none !important;
    }
  }
`;
