import { t } from '@lingui/macro';

import { recordFilterList } from 'services/shapes';

export const columns = () => [
  {
    width: 100,
    Header: t`Line ID`,
    accessor: 'lineSid',
    className: '',
  },
  {
    width: 400,
    Header: t`Description`,
    accessor: 'description',
    className: '',
    withTooltip: true,
  },
  {
    width: 120,
    Header: t`Amount`,
    accessor: 'amount',
    className: 'text-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 120,
    Header: t`Status`,
    accessor: 'reconciliationStatus',
    className: '',
    cell: {
      converter: (value) =>
        recordFilterList().find((item) => item.id === value)?.label,
    },
  },
  {
    width: 120,
    Header: t`Transaction ID`,
    accessor: 'sourceDocumentId',
    className: '',
  },
];

export default { columns };
