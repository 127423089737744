import React from 'react';
import { Trans } from '@lingui/macro';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import { Modal, ButtonGroup } from 'react-bootstrap';

import {
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';
import { cleanReconsModalColumns } from 'services/tableshapes/index';
import Paragraph from 'components/atoms/Paragraph/index';

function CleanReconsModal(props) {
  const { data, toggleModal, handleAction, loading, account } = props;

  return (
    <Modal show onEscapeKeyDown={() => toggleModal(false)} bsSize="large">
      <Modal.Header>
        <Heading level={3} style={{ margin: 0 }}>
          <Trans>Clean Reconciliations for Account {account}</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Paragraph kind="primary" alignment="justify">
          <Trans>
            Reconciliations can be marked as invalid if the underlying data has
            been modified, and we failed to undo the reconciliation while
            downloading new balance data.
          </Trans>
        </Paragraph>
        <CustomTableWithAutoSettings
          data={data}
          loading={loading}
          columns={cleanReconsModalColumns()}
          defaultSortBy="name"
          tableId="clean-recons-modal"
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup bsSize="small">
          <Button
            magnify
            onClick={() => handleAction('showAlert')}
            disabled={loading || !data.length}
          >
            <Trans>Clean Reconciliations</Trans>
          </Button>
          <Button fill kind="danger" magnify onClick={() => toggleModal(false)}>
            <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default CleanReconsModal;
